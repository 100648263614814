import '../styles/Home.css';

const Home = () => (
  <div className="Home-page">
    <h1>Welcome To Our Page</h1>

    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laudantium quisquam unde vel
      explicabo doloribus. Aliquid a accusantium omnis hic voluptate repellat et expedita
      voluptas cupiditate id optio, exercitationem, maiores temporibus!
      In maxime eius dolorum et error unde similique, suscipit deserunt nobis
      id eum iste quia molestias aliquam reiciendis illo, obcaecati qui? Ab
      adipisci molestias temporibus repellat tempore, beatae velit rem.
      Nam totam distinctio officia rem? Deserunt deleniti suscipit dolorem
      harum est natus dolore velit, reiciendis, qui nam id ab! Minima, facilis?
    </p>

    <p>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laudantium quisquam unde vel
      explicabo doloribus. Aliquid a accusantium omnis hic voluptate repellat et expedita
      voluptas cupiditate id optio, exercitationem, maiores temporibus!
      In maxime eius dolorum et error unde similique, suscipit deserunt nobis
      id eum iste quia molestias aliquam reiciendis illo, obcaecati qui? Ab
      adipisci molestias temporibus repellat tempore, beatae velit rem.
      Nam totam distinctio officia rem? Deserunt deleniti suscipit dolorem
      harum est natus dolore velit, reiciendis, qui nam id ab! Minima, facilis?
    </p>
  </div>
);

export default Home;
